
export default {
  name: 'TilesSliceTile',
  props: ['tile'],
  data() {
    return {
      detailsToggle: false
    }
  },
  computed: {
    hasDetails(){
      return this.tile.tile_details && this.tile.tile_details.length && this.tile.tile_details[0].text?.length
    }
  }
}
